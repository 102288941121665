import React from "react"
import Header from "../Header"


export default function Presentations() {
    return (
        <>
            <Header title="Presentations"></Header>
            <p>PDFs of the presentations I've given at Windsor Hackforge and other venues</p>
            <ul>
                <li><a href="https://github.com/johnhaldeman/hackfReactAndAngular/blob/master/ReactAngular.pdf">React and Angular - Side by Side Examples</a> (January 2018)</li>
                <li><a href="https://github.com/johnhaldeman/hackfGrammarTalk/blob/master/Grammars.pdf">Parsing with Grammars and ANTLR</a> (September 2017)</li>
                <li><a href="https://github.com/johnhaldeman/hackfMemoryHistoryTalk/blob/master/Memory.pdf">History of Main Memory</a> (August 2017)</li>
                <li><a href="https://github.com/johnhaldeman/hackfMaskingTalk/blob/master/MaskingPresentation.pdf">Desensitizing Non-Production (Masking Data)</a> (June 2017)</li>
                <li><a href="https://github.com/johnhaldeman/hackfEncryptionTalk/blob/master/Encryption.pdf">Sniffing and Not Getting Sniffed (Introduction to public key encryption)</a> (May 2017)</li>
                <li><a href="https://github.com/johnhaldeman/hackfBabbageTalk/blob/master/Babbage.pdf">Babbage, Logarithm Tables, and The Difference Engine</a> (April/May 2017)</li>
                <li><a href="https://github.com/johnhaldeman/hackfProtobufTalk/blob/master/ProtocolBuffers.pdf">Protocol Buffers</a> (November 2016)</li>
                <li><a href="https://github.com/johnhaldeman/hackfNoSQLTalk/blob/master/NoSQL.pdf">NoSQL Cocktail Hour</a> (August 2016)</li>
                <li><a href="https://github.com/johnhaldeman/hackfAuthenticationTalk/blob/master/Authentication.pdf">Authentication When You Aren't Human</a> (August 2015)</li>
            </ul>
        </>
    )

}